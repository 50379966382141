<template>
  <div
    ref="segmentedControlRoot"
    :class="[
      'p-segmented-control',
      { 'p-segmented-control--underlined': underlined, 'p-segmented-control--disabled': disabled },
    ]"
  >
    <div class="p-segmented-control__wrapper" :size="size">
      <template v-for="(item, index) in items" :key="item.key">
        <component
          :is="componentName(item)"
          :icon="item.icon"
          :size="size"
          v-bind="item.url ? { to: item.url } : {}"
          :disabled="disabled"
          :class="[
            'p-segmented-control__item',
            {
              'box-gradient': item.key === 'box_tl',
              'p-segmented-control__item--selected': internalIndex === index,
            },
          ]"
          @click="(e: MouseEvent) => handleClick(e.currentTarget as HTMLElement, item.key, index)"
        >
          <template v-if="!item.icon">
            {{ item.display }}
          </template>
          <p-icon
            v-if="item.appendIcon"
            :icon="item?.appendIcon"
            size="sm"
            color="fg-secondary"
            class="ml-4"
          />
        </component>
      </template>
      <div class="p-segmented-control__selected" :style="selectedStyle" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

interface Item {
  display: string | number
  key: string
  url?: string | object
  icon?: string
  appendIcon?: string
}

const props = defineProps({
  items: {
    type: Array as () => Item[],
    required: true,
  },
  size: {
    type: String,
    default: 'md',
    validator: (val: string) => ['sm', 'md', 'lg'].includes(val),
  },
  selectedIndex: {
    type: Number,
    default: 0,
  },
  underlined: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'on-change'])

const route = useRoute()

const internalIndex = ref<number>(props.selectedIndex)
const selectedTarget = ref<HTMLElement | null>(null)
const segmentedControlRoot = ref<HTMLElement | null>(null)

function handleClick(target: HTMLElement, key: string, index: number) {
  internalIndex.value = index
  selectedTarget.value = target

  $router().push({
    name: route.name,
    params: route.params,
    query: { ...route.query, [props.id]: key },
  })

  emit('update:modelValue', key)
  emit('on-change')
}

const selectedStyle = computed(() => {
  const targetWidth = selectedTarget.value?.clientWidth || 0
  const targetLeft = selectedTarget.value?.offsetLeft || 0
  const width = props.underlined ? `${targetWidth}px` : `${100 / props.items.length}%`
  const left = props.underlined
    ? `${targetLeft}px`
    : `${(100 / props.items.length) * internalIndex.value}%`
  return `width:${width}; left:${left}`
})

function componentName(item: Items) {
  if (item.icon) return resolveComponent('p-icon')
  if (item.url) return resolveComponent('nuxt-link')
  return 'div'
}

function setIndexFromRouter() {
  const queryKey = props.id
  const matchedIndex = props.items.findIndex((item) => item.key === route.query[queryKey])
  internalIndex.value = matchedIndex >= 0 ? matchedIndex : props.selectedIndex
  nextTick(() => {
    setTimeout(() => setInitialSelectedTarget(), 0)
  })
}

function setInitialSelectedTarget() {
  nextTick(() => {
    if (segmentedControlRoot.value) {
      const segments = segmentedControlRoot.value.querySelectorAll('.p-segmented-control__item')
      if (segments && segments[internalIndex.value]) {
        selectedTarget.value = segments[internalIndex.value] as HTMLElement
      }
    }
  })
}

watch(
  () => route.fullPath,
  () => {
    setIndexFromRouter()
  },
  { immediate: true },
)

watch(
  () => props.modelValue,
  (newVal) => {
    const idx = props.items.findIndex((item) => item.key === newVal)
    internalIndex.value = idx >= 0 ? idx : 0
    nextTick(() => setInitialSelectedTarget())
  },
  { immediate: true },
)

onMounted(() => {
  setIndexFromRouter()
})
</script>
